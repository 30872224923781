/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React, { useState, useCallback, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form } from '@unform/web'
import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import NameAndTimeInterval from '~/components/unform/FormGroup/NameAndTimeInterval'
import { handleValidationsErrors } from '~/helpers'
import DashboardCampaign from '~/modules/messagingCampaigns/components/DashboardCampaign'
import campaignActions from '~/modules/messagingCampaigns/store/campaign/actions'

import Callback from './Callback'
import { model } from './model'
import Recipients from './Recipients'
import Template from './Template'
import { schemaYup } from './yup'

import * as S from './styles'

const FormCampaign = ({ initialData, isEdit }) => {
  const [template, setTemplate] = useState(null)

  const formRef = useRef(null)

  const dispatch = useDispatch()

  const { saveCampaignLoading: loading, campaign } = useSelector(
    state => state.messagingCampaigns
  )

  const campaignId = campaign?.item?.data?._id

  /**
   * Handle template change
   */

  const handleChangeTemplate = data => {
    console.log('🚀 ~ handleChangeTemplate ~ data:', data)
    setTemplate(data)
  }

  /**
   * Handle submit
   */

  const handleSubmit = useCallback(
    async formData => {
      try {
        if (!template) return

        formRef.current?.setErrors({})

        await schemaYup.validate(formData, {
          abortEarly: false
        })

        const body = model({
          ...formData,
          template_id: formData?.template_id || initialData?.template_id
        })

        if (formData?.file) {
          // eslint-disable-next-line no-console
          console.warn(
            'O método de upload ainda não foi implementado. Enviar multidata com objeto aninhado requer um tratamento especial.'
          )
        }

        if (isEdit) {
          dispatch(campaignActions.update({ body, id: campaignId }))
          return
        }

        dispatch(campaignActions.create({ body }))
      } catch (err) {
        console.error({ err })

        handleValidationsErrors(err, formRef)
      }
    },
    [template, initialData, isEdit, dispatch, campaignId]
  )

  const templateHasButtons = useMemo(() => template?.buttons, [template])

  return (
    <S.Container>
      <DashboardCampaign />

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={initialData || { scheduleMode: 'sendLater', ...campaign }}
      >
        {/* <Maininfo /> */}
        <NameAndTimeInterval
          formRef={formRef}
          labelTitle="Nome da campanha"
          placeholderTitle="Digite o nome da campanha"
          hasDescription
          labelDescription="Descrição (opcional)"
          placeholderDescription="Digite a descrição da campanha"
          labelInterval="Período da campanha"
        />
        {/*  */}
        <Template
          onChange={handleChangeTemplate}
          initialTemplate={initialData?.template_id}
          initialData={initialData}
          formRef={formRef}
        />
        {template && (
          <>
            {templateHasButtons && (
              <Callback formRef={formRef} initialData={initialData} />
            )}
            {/*  */}
            <Recipients />
            {/*  */}
            {/* <Time /> */}
            <div className="form-default__buttons">
              <Button
                type="submit"
                text="Salvar"
                loading={loading}
                size="large"
                template="success"
              />
            </div>
          </>
        )}
      </Form>
    </S.Container>
  )
}

export default FormCampaign

FormCampaign.defaultProps = {
  initialData: null,
  isEdit: false
}

FormCampaign.propTypes = {
  isEdit: PropTypes.bool,
  initialData: PropTypes.shape({
    template_id: PropTypes.string
  })
}
